const API_VERSION = '1.0.0'

export let SITE_NAME = 'Florida'
export let CURRENCY = '৳'

// const ROOT_URL = 'http://185.210.144.62:3001';
// export const IMAGE_URL = 'http://185.210.144.62:3001';
// export const FILE_URL = 'http://185.210.144.62:3001';
const ROOT_URL = 'https://api.floridafarmsbd.com';
export const IMAGE_URL = 'https://api.floridafarmsbd.com';
export const FILE_URL = 'https://api.floridafarmsbd.com';

// export const FILE_URL = 'http://localhost:8080'
// const ROOT_URL = 'http://localhost:8080'
// export const IMAGE_URL = 'http://localhost:8080'

// const ROOT_URL = 'http://18.141.244.128:6000';
// export const IMAGE_URL = 'http://18.141.244.128:6000';
// export const FILE_URL = 'http://18.141.244.128:6000';

// const ROOT_URL = 'http://192.168.1.3:3001';
// export const IMAGE_URL = 'http://192.168.1.3:3001';
// export const FILE_URL = 'http://192.168.1.3:3001';

const API_URL = `${ROOT_URL}/api/v/${API_VERSION}`

export const PUBLIC_API_URL = `${API_URL}`
export const PRIVATE_API_URL = `${API_URL}`

//auth
export const LOGIN_URL = `${PUBLIC_API_URL}/login`
export const REFRESH_TOKEN = `${PRIVATE_API_URL}/login/refresh-token`

//product
export const GET_PRODUCT_LIST = `${PRIVATE_API_URL}/products/private-products`
export const GET_PRODUCT_BY_ID = `${PRIVATE_API_URL}/products/id`
export const CREATE_PRODUCT_URL = `${PRIVATE_API_URL}/products/create`
export const UPDATE_PRODUCT_URL = `${PRIVATE_API_URL}/products/update`
export const VERIFY_PRODUCT_URL = `${PRIVATE_API_URL}/products/verify`
export const CREATE_VARIANT_URL = `${PRIVATE_API_URL}/product-variants/create`
export const UPDATE_VARIANT_URL = `${PRIVATE_API_URL}/product-variants/update`
export const DELETE_VARIANT_URL = `${PRIVATE_API_URL}/product-variants/delete`
export const DELETE_PRODUCT_URL = `${PRIVATE_API_URL}/products/delete`
export const APPLY_SUBSCRIPTION_PLAN = `${PRIVATE_API_URL}/products/apply-subscription-plan`
export const PRODUCT_ACTIVATE_DEACTIVATE_URL = `${PRIVATE_API_URL}/products/activate-or-deactivate`

//user
export const GET_USER_LIST = `${PRIVATE_API_URL}/users`
export const GET_USER_PROFILE = `${PRIVATE_API_URL}/users/profile`
export const GET_USER_BY_ID = `${PRIVATE_API_URL}/users/id`
export const CREATE_USER_URL = `${PRIVATE_API_URL}/users/create`
export const UPDATE_USER_URL = `${PRIVATE_API_URL}/users/update`
export const CHANGE_PASSWORD_URL = `${PRIVATE_API_URL}/users/change-user-password`

//seller
export const GET_SELLER_LIST = `${PRIVATE_API_URL}/sellers`
export const UPDATE_SELLER_PROFILE_URL = `${PRIVATE_API_URL}/sellers/seller-profile/update`
export const VERIFY_SELLER_URL = `${PRIVATE_API_URL}/sellers/verify`
export const GET_SELLER_ACCOUNTS = `${PRIVATE_API_URL}/sellers/accounts`

//company
export const GET_COMPANY_LIST = `${PRIVATE_API_URL}/companies`
export const GET_COMPANY_BY_ID = `${PRIVATE_API_URL}/companies/id`
export const POST_COMPANY_URL = `${PRIVATE_API_URL}/companies/create`
export const UPDATE_COMPANY_URL = `${PRIVATE_API_URL}/companies/update`
export const VERIFY_COMPANY_URL = `${PRIVATE_API_URL}/companies/verify`

//warehouse
export const GET_WAREHOUSE_LIST = `${PRIVATE_API_URL}/warehouses`
export const GET_WAREHOUSE_BY_ID = `${PRIVATE_API_URL}/warehouses/id`
export const POST_WAREHOUSE_URL = `${PRIVATE_API_URL}/warehouses/create`
export const UPDATE_WAREHOUSE_URL = `${PRIVATE_API_URL}/warehouses/update`
export const DELETE_WAREHOUSE_URL = `${PRIVATE_API_URL}/warehouses/delete`
export const GET_WAREHOUSES_TO_SEND_STOCK = `${PRIVATE_API_URL}/stock-outs/warehouses-to-send-stock`

//banner
export const GET_BANNER_LIST = `${PRIVATE_API_URL}/banners/private`
export const GET_BANNER_BY_ID = `${PRIVATE_API_URL}/banners/id`
export const POST_BANNER_URL = `${PRIVATE_API_URL}/banners/create`
export const UPDATE_BANNER_URL = `${PRIVATE_API_URL}/banners/update`
export const DELETE_BANNER_URL = `${PRIVATE_API_URL}/banners/delete`

//static-page
export const GET_STATIC_PAGE_LIST = `${PRIVATE_API_URL}/static-pages`
export const GET_STATIC_PAGE_BY_ID = `${PRIVATE_API_URL}/static-pages/id`
export const GET_STATIC_PAGE_BY_TITLE = `${PRIVATE_API_URL}/static-pages/title`
export const POST_STATIC_PAGE_URL = `${PRIVATE_API_URL}/static-pages/create`
export const UPDATE_STATIC_PAGE_URL = `${PRIVATE_API_URL}/static-pages/update`
export const DELETE_STATIC_PAGE_URL = `${PRIVATE_API_URL}/static-pages/delete`

//role
export const GET_ROLE_LIST = `${PRIVATE_API_URL}/roles`
export const GET_ROLE_BY_ID = `${PRIVATE_API_URL}/roles/id`
export const POST_ROLE_URL = `${PRIVATE_API_URL}/roles/create`
export const UPDATE_ROLE_URL = `${PRIVATE_API_URL}/roles/update`
export const ALL_PERMISSION_URL = `${PRIVATE_API_URL}/roles/all-permissions`

//stock
export const GET_STOCK_LIST = `${PRIVATE_API_URL}/stocks`
export const GET_STOCK_BY_ID = `${PRIVATE_API_URL}/stocks/id`
export const POST_STOCK_URL = `${PRIVATE_API_URL}/stocks/create`
export const UPDATE_STOCK_URL = `${PRIVATE_API_URL}/stocks/update`
export const DELETE_STOCK_URL = `${PRIVATE_API_URL}/stocks/delete`

//stock
export const GET_STOCK_OUT_LIST = `${PRIVATE_API_URL}/stock-outs`
export const GET_STOCK_OUT_BY_ID = `${PRIVATE_API_URL}/stock-outs/id`
export const POST_STOCK_OUT_URL = `${PRIVATE_API_URL}/stock-outs/create`
export const UPDATE_STOCK_OUT_URL = `${PRIVATE_API_URL}/stock-outs/update`
export const DELETE_STOCK_OUT_URL = `${PRIVATE_API_URL}/stock-outs/delete`
export const VERIFY_STOCK_URL = `${PRIVATE_API_URL}/stock-outs/verify`

//category
export const GET_CATEGORY_LIST = `${PRIVATE_API_URL}/categories`
export const GET_PARENT_CATEGORY_LIST = `${PRIVATE_API_URL}/categories/parents`
export const GET_CATEGORY_BY_ID = `${PRIVATE_API_URL}/categories/id`
export const POST_CATEGORY_URL = `${PRIVATE_API_URL}/categories/create`
export const UPDATE_CATEGORY_URL = `${PRIVATE_API_URL}/categories/update`
export const VERIFY_CATEGORY_URL = `${PRIVATE_API_URL}/categories/verify`
export const DELETE_CATEGORY_URL = `${PRIVATE_API_URL}/categories/delete`
export const MAKE_HOT_CATEGORY_URL = `${PRIVATE_API_URL}/categories/hot`

//order
export const GET_ORDER_LIST_URL = `${PRIVATE_API_URL}/orders`
export const GET_ORDER_BY_ID_URL = `${PRIVATE_API_URL}/orders/id`
export const PROCESS_ORDER_URL = `${PRIVATE_API_URL}/orders/process`
export const UPDATE_ORDER_URL = `${PRIVATE_API_URL}/orders/update/id`
export const UPDATE_CART_URL = `${PRIVATE_API_URL}/carts/update/id`
export const DELETE_ORDER_URL = `${PRIVATE_API_URL}/orders/delete`

//homepage
export const GET_HOME_PAGE_PRODUCT_LIST = `${PRIVATE_API_URL}/products/homepage-products`
export const GET_HOME_PAGE_PRODUCT_BY_ID = `${PRIVATE_API_URL}/products/homepage-products/id`
export const POST_HOME_PAGE_PRODUCT_URL = `${PRIVATE_API_URL}/products/homepage-products/create`
export const UPDATE_HOME_PAGE_PRODUCT_URL = `${PRIVATE_API_URL}/products/homepage-products/update`
export const DELETE_HOME_PAGE_PRODUCT_URL = `${PRIVATE_API_URL}/products/homepage-products/delete`

//featured
export const GET_FEATURED_PRODUCT_LIST = `${PRIVATE_API_URL}/products/featured-products`
export const GET_FEATURED_PRODUCT_BY_ID = `${PRIVATE_API_URL}/products/featured-products/id`
export const POST_FEATURED_PRODUCT_URL = `${PRIVATE_API_URL}/products/featured-products/create`
export const UPDATE_FEATURED_PRODUCT_URL = `${PRIVATE_API_URL}/products/featured-products/update`
export const DELETE_FEATURED_PRODUCT_URL = `${PRIVATE_API_URL}/products/featured-products/delete`

//dailydeals
export const GET_DAILY_DEALS_PRODUCT_LIST = `${PRIVATE_API_URL}/products/daily-deals`
export const GET_DAILY_DEALS_PRODUCT_BY_ID = `${PRIVATE_API_URL}/products/daily-deals/id`
export const POST_DAILY_DEALS_PRODUCT_URL = `${PRIVATE_API_URL}/products/daily-deals/create`
export const UPDATE_DAILY_DEALS_PRODUCT_URL = `${PRIVATE_API_URL}/products/daily-deals/update`
export const DELETE_DAILY_DEALS_PRODUCT_URL = `${PRIVATE_API_URL}/products/daily-deals/delete`

//best-seller
export const GET_BEST_SELLER_PRODUCT_LIST = `${PRIVATE_API_URL}/products/best-seller`
export const GET_BEST_SELLER_PRODUCT_BY_ID = `${PRIVATE_API_URL}/products/best-seller/id`
export const POST_BEST_SELLER_PRODUCT_URL = `${PRIVATE_API_URL}/products/best-seller/create`
export const UPDATE_BEST_SELLER_PRODUCT_URL = `${PRIVATE_API_URL}/products/best-seller/update`
export const DELETE_BEST_SELLER_PRODUCT_URL = `${PRIVATE_API_URL}/products/best-seller/delete`

//image upload
export const UPLOAD_CATEGORY_IMAGE = `${PRIVATE_API_URL}/upload/category-icon`
export const UPLOAD_BANNER_IMAGE = `${PRIVATE_API_URL}/upload/banner`
export const UPLOAD_COMPANY_IMAGE = `${PRIVATE_API_URL}/upload/company`
export const UPLOAD_SITE_IMAGE = `${PRIVATE_API_URL}/upload/site-images`

export const UPLOAD_PRODUCT_IMAGE = `${PRIVATE_API_URL}/upload/product-image`
export const UPLOAD_PROFILE_PICTURE = `${PRIVATE_API_URL}/upload/profile-picture`
export const UPLOAD_SELLER_DOC = `${PRIVATE_API_URL}/upload/seller-doc`
export const UPLOAD_SELLER_IMAGE = `${PRIVATE_API_URL}/upload/seller-images`
export const UPLOAD_PRODUCT_BUNDLE_OFFER_IMAGE = `${PRIVATE_API_URL}/upload/product-bundle-offer`

//global-config
export const GET_GLOBAL_CONFIG_LIST = `${PRIVATE_API_URL}/global-configs`
export const GET_GLOBAL_CONFIG_BY_ID = `${PRIVATE_API_URL}/global-configs/id`
export const GET_ACTIVE_GLOBAL_CONFIG = `${PRIVATE_API_URL}/global-configs/active`
export const POST_GLOBAL_CONFIG_URL = `${PRIVATE_API_URL}/global-configs/create`
export const UPDATE_GLOBAL_CONFIG_URL = `${PRIVATE_API_URL}/global-configs/update`
export const DELETE_GLOBAL_CONFIG_URL = `${PRIVATE_API_URL}/global-configs/delete`

//promo-code
export const GET_PROMO_CODE_LIST = `${PRIVATE_API_URL}/promo-codes`
export const GET_PROMO_CODE_BY_ID = `${PRIVATE_API_URL}/promo-codes/id`
export const POST_PROMO_CODE_URL = `${PRIVATE_API_URL}/promo-codes/create`
export const UPDATE_PROMO_CODE_URL = `${PRIVATE_API_URL}/promo-codes/update`
export const DELETE_PROMO_CODE_URL = `${PRIVATE_API_URL}/promo-codes/delete`

//area
export const GET_AREA_LIST = `${PRIVATE_API_URL}/areas`
export const GET_AREA_BY_ID = `${PRIVATE_API_URL}/areas/id`
export const POST_AREA_URL = `${PRIVATE_API_URL}/areas/create`
export const UPDATE_AREA_URL = `${PRIVATE_API_URL}/areas/update`
export const DELETE_AREA_URL = `${PRIVATE_API_URL}/areas/delete`

export const GET_DELIVERY_PARTNER_LIST = `${PRIVATE_API_URL}/delivery-partners`
export const GET_DELIVERY_PARTNER_BY_ID = `${PRIVATE_API_URL}/delivery-partners/id`
export const POST_DELIVERY_PARTNER_URL = `${PRIVATE_API_URL}/delivery-partners/create`
export const UPDATE_DELIVERY_PARTNER_URL = `${PRIVATE_API_URL}/delivery-partners/update`
export const DELETE_DELIVERY_PARTNER_URL = `${PRIVATE_API_URL}/delivery-partners/delete`
export const GET_DELIVERY_PARTNER_ACCOUNTS = `${PRIVATE_API_URL}/delivery-partners/accounts`

//product-bundle-offer
export const GET_PRODUCT_BUNDLE_OFFER_LIST = `${PRIVATE_API_URL}/product-bundle-offers`
export const GET_PRODUCT_BUNDLE_OFFER_BY_ID = `${PRIVATE_API_URL}/product-bundle-offers/id`
export const POST_PRODUCT_BUNDLE_OFFER_URL = `${PRIVATE_API_URL}/product-bundle-offers/create`
export const UPDATE_PRODUCT_BUNDLE_OFFER_URL = `${PRIVATE_API_URL}/product-bundle-offers/update`
export const DELETE_PRODUCT_BUNDLE_OFFER_URL = `${PRIVATE_API_URL}/product-bundle-offers/delete`

export const GET_CAMPAIGN_LIST = `${PRIVATE_API_URL}/campaigns`
export const GET_CAMPAIGN_BY_ID = `${PRIVATE_API_URL}/campaigns/id`
export const POST_CAMPAIGN_URL = `${PRIVATE_API_URL}/campaigns/create`
export const UPDATE_CAMPAIGN_URL = `${PRIVATE_API_URL}/campaigns/update`
export const DELETE_CAMPAIGN_URL = `${PRIVATE_API_URL}/campaigns/delete`

//seller-ledge
export const GET_SELLER_LEDGER_LIST = `${PRIVATE_API_URL}/sellers/ledgers`
// export const GET_SELLER_LEDGER_BY_ID = `${PRIVATE_API_URL}/ledgers/id`;
export const POST_SELLER_LEDGER_URL = `${PRIVATE_API_URL}/sellers/payment/create`
export const CANCEL_SELLER_LEDGER_URL = `${PRIVATE_API_URL}/sellers/payment/cancel`
// export const UPDATE_SELLER_LEDGER_URL = `${PRIVATE_API_URL}/ledgers/update`;
// export const DELETE_SELLER_LEDGER_URL = `${PRIVATE_API_URL}/ledgers/delete`;

//delivery-partner-ledge
export const GET_DELIVERY_PARTNER_LEDGER_LIST = `${PRIVATE_API_URL}/delivery-partners/ledgers`
// export const GET_SELLER_LEDGER_BY_ID = `${PRIVATE_API_URL}/ledgers/id`;
export const POST_DELIVERY_PARTNER_LEDGER_URL = `${PRIVATE_API_URL}/delivery-partners/payment/create`
export const CANCEL_DELIVERY_PARTNER_LEDGER_URL = `${PRIVATE_API_URL}/delivery-partners/payment/cancel`

//subscription-plan
export const GET_SUBSCRIPTION_PLAN_LIST = `${PRIVATE_API_URL}/subscription-plans`
export const GET_SUBSCRIPTION_PLAN_BY_ID = `${PRIVATE_API_URL}/subscription-plans/id`
export const POST_SUBSCRIPTION_PLAN_URL = `${PRIVATE_API_URL}/subscription-plans/create`
export const UPDATE_SUBSCRIPTION_PLAN_URL = `${PRIVATE_API_URL}/subscription-plans/update`
export const DELETE_SUBSCRIPTION_PLAN_URL = `${PRIVATE_API_URL}/subscription-plans/delete`

export const CHANGE_SUBSCRIPTION_PLAN = `${PRIVATE_API_URL}/sellers/change-subscription-plan`

//customer group
export const GET_CUSTOMER_GROUP_LIST = `${PRIVATE_API_URL}/customer-groups`
export const GET_CUSTOMER_GROUP_BY_ID = `${PRIVATE_API_URL}/customer-groups/id`
export const POST_CUSTOMER_GROUP_URL = `${PRIVATE_API_URL}/customer-groups/create`
export const UPDATE_CUSTOMER_GROUP_URL = `${PRIVATE_API_URL}/customer-groups/update`
export const DELETE_CUSTOMER_GROUP_URL = `${PRIVATE_API_URL}/customer-groups/delete`

//chatting
export const GET_ALL_CHATTING_LIST = `${PRIVATE_API_URL}/chatting/chatting-list`
export const GET_CHATTING_HISTORY_BY_USER_ID = `${PRIVATE_API_URL}/chatting/history/id`

//dashboard
export const GET_NOTIFICATION_LIST = `${PRIVATE_API_URL}/dashboard/notifications`

// Token & Profile

export const ACCESS_TOKEN = 'Florida-ecom-access-token'
export const PROFILE = 'Florida-ecom-profile'

// Order State

export const ORDER_STATE_SEQUENCE = {
    INITIATED: 'SUPPLIER_ACCEPTED',
    SUPPLIER_ACCEPTED: 'DELIVERY_MAN_PICKED',
    DELIVERY_MAN_PICKED: 'COMPLETED',
    COMPLETED: ''
}

export const INITIATED = 'INITIATED'
export const SUPPLIER_ACCEPTED = 'SUPPLIER_ACCEPTED'
export const DELIVERY_MAN_PICKED = 'DELIVERY_MAN_PICKED'
export const COMPLETED = 'COMPLETED'
export const CANCELED = 'CANCELED'

export const ORDER_STATE_COLOR = {
    INITIATED: '',
    SUPPLIER_ACCEPTED: 'cyan',
    DELIVERY_MAN_PICKED: 'blue',
    COMPLETED: 'green',
    CANCELED: 'red'
}

// Currency
export const BD_TK_SIGN = '৳ '

// Gender

export const GENDER_COLOR = {
    MALE: 'blue',
    FEMALE: 'green',
    OTHERS: 'purple'
}
export const COMPANY = {
    name: 'Florida',
    address: 'H-12, 1st FL, Dolipara, Jashimuddin Avenue, Uttara, Dhaka-1230'
}

//end
